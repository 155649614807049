import Vue from 'vue'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'

import router from './router'

if (process.env.NODE_ENV === 'production') {
	Vue.use(VueFacebookPixel, {
		router,
	})

	Vue.analytics.fbq.init('1623930004537293')
}
