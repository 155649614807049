import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueGtm from 'vue-gtm'

import './analytics'

import App from './App'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(Vuelidate)

Vue.use(VueGtm, {
	id: 'GTM-WWBG2N',
	enabled: process.env.NODE_ENV === 'production',
	debug: false,
	vueRouter: router,
	ignoredViews: ['NotFound'],
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
