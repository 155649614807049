import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
	mode: 'history',

	base: process.env.BASE_URL,

	routes: [{
		path: '/gift-guide',
		name: 'GiftGuide',
		meta: {
			title: 'Gift Guide | DISHForMyRV.com',
			metaTags: [{
				name: 'description',
				content: 'DISH For My RV provides pay as you go portable satellite TV service. Watch outdoor TV while on the go. Learn more about how you can get DISH outdoors.',
			}, {
				name: 'keywords',
				content: 'DISH Playmaker, Playmaker, DISH, Coupon, Offer, Discount',
			}]
		},
		component: () => import( /* webpackChunkName: "GiftGuide" */ './views/GiftGuide'),
	}, {
		path: '/learn-more',
		name: 'LearnMore',
		meta: {
			title: 'Learn More | DISHForMyRV.com',
			metaTags: [{
				name: 'description',
				content: 'DISH For My RV provides pay as you go portable satellite TV service. Watch outdoor TV while on the go. Learn more about how you can get DISH outdoors.',
			}, {
				name: 'keywords',
				content: 'DISH Playmaker, Playmaker, DISH, Coupon, Offer, Discount',
			}]
		},
		component: () => import( /* webpackChunkName: "LearnMore" */ './views/LearnMore'),
	}, {
		path: '*',
		name: 'NotFound',
		component: () => import( /* webpackChunkName: "NotFound" */ './views/HttpStatus/NotFound'),
	}],
})

router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) document.title = nearestWithTitle.meta.title

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next()

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map(tagDef => {
		const tag = document.createElement('meta')

		Object.keys(tagDef).forEach(key => {
			tag.setAttribute(key, tagDef[key])
		})

		tag.setAttribute('data-vue-router-controlled', '')

		return tag;
	}).forEach(tag => document.head.appendChild(tag))

	next();
});

router.afterEach(to => {
	const _hsq = window._hsq = window._hsq || []
	_hsq.push(['setPath', to.fullPath])
	_hsq.push(['trackPageView'])
})

export default router
